import React, {useContext, Suspense} from 'react';
import {Grid} from '@mui/material';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

const Navbar = React.lazy(() => import ('./Navigation/Navbar'));
const Dashboard = React.lazy(() => import ('./Pages/Dashboard'));
const Asignaciones = React.lazy(() => import ('./Pages/Asignaciones'));
const Perfiles = React.lazy(() => import ('./Pages/Perfiles'));
const Soporte = React.lazy(() => import ('./Pages/Soporte'));
const LogIn = React.lazy(() => import ('./Pages/LogIn'));
const Footer = React.lazy(() => import ('./Navigation/Footer'));

export const AuthContext = React.createContext();

const useAuth = () => {
    return useContext(AuthContext);
};

const numberToMonth = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
}

function App() {
    const [rowsRuta, setRowsRuta] = React.useState([]);
    const [rowsConductor, setRowsConductor] = React.useState([]);
    const [rowsConductorRuta, setRowsConductorRuta] = React.useState([]);

    async function fetchRutaData() {
        try {
            const response = await fetch('https://ph.trackthetrucker.tech/dashboard/incidente/cervecerias');
            const data = await response.json();
            setRowsRuta(data.map(({ 
                region, 
                city_start: startcity, 
                city_end: endcity, 
                month: rawMonth, 
                year: rawYear, 
                TotalMaxSpeed: totalspeed, 
                TotalDrowsiness: totalsleep, 
                TotalAccident: totalaccident 
            }) => ({
                region,
                startcity,
                endcity,
                month: numberToMonth[rawMonth],
                year: rawYear.toString(),
                totalspeed,
                totalsleep,
                totalaccident
            })));
        } catch (error) {
            console.error('Error al cargar datos:', error);
        }
    }

    async function fetchConductorData() {
        try {
            const response = await fetch('https://ph.trackthetrucker.tech/dashboard/perfil/cervecerias');
            const data = await response.json();
            setRowsConductor(data.map(({
                Nombre: driver,
                personalidad: risk,
                totaltrips,
                TotalMaxSpeed: totalspeed,
                TotalDrowsiness: totalsleep,
                TotalAccident: totalaccident,
                avgspeed_min: minspeed,
                avgspeed_max: maxspeed,
                avgspeed,
                monthly_avgspeed: monthlyspeed,
                monthly_absdistance: monthlydistance,
                yearly_avgspeed: yearlyspeed,
                yearly_absdistance: yearlydistance,
                driverid
            }) => ({
                driver,
                risk,
                totaltrips,
                totalspeed,
                totalsleep,
                totalaccident,
                minspeed,
                maxspeed,
                avgspeed,
                monthlyspeed,
                monthlydistance,
                yearlyspeed,
                yearlydistance,
                driverid
            })));
        } catch (error) {
            console.error('Error al cargar datos de conductor:', error);
        }
    }

    async function fetchConductorRutaData() {
        try {
            const response = await fetch('https://ph.trackthetrucker.tech/dashboard/ruta/cervecerias');
            const data = await response.json();
            setRowsConductorRuta(data.map(({
                Nombre: driver,
                personalidad: risk,
                city_start: startcity,
                city_end: endcity,
                trip_count: totaltrips,
                avgspeed: meanspeed,
                avgspeed_max: maxspeed,
                incident_count: totalincidents,
            }) => ({
                driver,
                risk,
                startcity,
                endcity,
                totaltrips,
                meanspeed,
                maxspeed,
                totalincidents
            })));
        } catch (error) {
            console.error('Error al cargar datos de conductor:', error);
        }
    }

    React.useEffect(() => {
        window.sessionStart = Date.now();
        fetchRutaData();
        fetchConductorRutaData();
        fetchConductorData();
    }, []);
    
    const [value,
        setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isLoggedIn,
        setIsLoggedIn] = React.useState(true);

    return (
        <Suspense fallback={< div > Loading ...</div>}>
            <AuthContext.Provider
                value={{
                isLoggedIn,
                setIsLoggedIn
            }}>
                <Router>
                    <Grid container>
                        <Grid
                            item
                            position='relative'
                            xs={12}
                            sx={{
                            zIndex: 1
                        }}>
                            <Navbar value={value} handleChange={handleChange}/>
                        </Grid>
                        <Grid
                            item
                            position='relative'
                            xs={12}
                            sx={{
                            mt: '-3rem',
                            zIndex: 2
                        }}>
                            <Routes>
                                <Route exact path="/login" element={<LogIn />} />
                                <Route path="/perfiles" element={<ProtectedComponent component={Perfiles} />} />
                                <Route path="/" element={<ProtectedComponent component={() => <Dashboard value={value} rowsRuta={rowsRuta} rowsConductor={rowsConductor} rowsConductorRuta={rowsConductorRuta}/>} />} />
                                <Route path="/asignaciones" element={<ProtectedComponent component={() => <Asignaciones value={value} />} />} />
                                <Route exact path="/soporte" element={<Soporte />} />
                            </Routes>
                        </Grid>
                        <Grid item xs={12} position='relative'>
                            <Footer />
                        </Grid>
                    </Grid>
                </Router>
            </AuthContext.Provider>
        </Suspense>
    );
}
export default App;

function ProtectedComponent({ component: Component }) {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    return <Component />;
}