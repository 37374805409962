import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {esES} from '@mui/material/locale';
import {createTheme, ThemeProvider} from '@mui/material/styles';

function sendSessionDurationToBackend(duration) {
    fetch("https://ph.trackthetrucker.tech/utils/sessionTime/cervecerias", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            duration: duration,
        }),
    });
}

window.addEventListener("beforeunload", function (event) {
    const sessionEnd = Date.now();
    const sessionDuration = sessionEnd - window.sessionStart;

    sendSessionDurationToBackend(sessionDuration);
});

const theme = createTheme({
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 14
    },
    palette: {
        primary: {
            main: '#0158EE'
        },
        error: {
            main: '#ff1744'
        },
        warning: {
            main: '#ff9100'
        }
    },
}, esES);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
            <App/>
    </ThemeProvider>
);

reportWebVitals();
